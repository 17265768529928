<template>
  <div class="wraper pagesxshenling">
    <div id="printDiv" v-if="showPrinter">ahahahahahha</div>

    <SXShenLTable ref="SXShenLTable"></SXShenLTable>
    <!--新增申购单-->
    <el-dialog title="" :visible.sync="dialogVisible_add" custom-class="cus_dialog cus_dialogFullHeight" width="90%">
      <div class="myDialogBox flexBetween">
        <div class="flexCloumnSingleCenter" style="height:100%;width: 40%;">
          <div class="boxShadow flexCloumnSingleCenter" style="height: 60%;width: calc( 100% - 0px );">
            <div class="titleBar flexStart">
              <div class="div flexStart">{{ dialogTitle }}</div>
            </div>
            <div class="flex_1" style="width:100%;overflow-y: auto;margin-bottom: 10px;">
              <el-form class="" :model="EditItem" ref="addzc" label-position="left" label-width="90px"
                style="width:calc( 100% - 10px );">

                <el-form-item label="申请人:">
                  <el-input :value="userinfo.username" size="mini" disabled></el-input>
                </el-form-item>
                <el-form-item label="所属部门:">
                  <el-cascader size="small" style="width:80%" ref="treeCascader" :options="deptTree"
                    :props="deptCascaderProps" v-model="EditItem.dept_id" :show-all-levels="false" @change="changeDept"
                    placeholder="请选择" clearable>
                  </el-cascader>
                </el-form-item>

                <el-form-item label="*申领原因:" prop="sl_remark">
                  <el-input type="textarea" :autosize="{ minRows: 3 }" v-model="EditItem.sl_remark" size="mini"
                    placeholder="请输入领用理由、用途等"></el-input>
                </el-form-item>


                <el-form-item label="图片:">
                  <div class="flexStart">
                    <div class="updimage" v-for="(f, idx) in files" :style="{ backgroundImage: 'url(' + f.src + ')' }"
                      style="position: relative">
                      <img :src="f.src" @click="handlePictureCardPreview(f.src)"
                        style="opacity: 0;width: 100%;height: 100%;" />
                      <div class="flexCenter" @click="delImage(idx)"
                        style="position: absolute;top: -8px;right: -8px;width:20px;height:20px;
                          color: #fff;background-color:#409eff;border-radius: 50%;border: 1px solid #fff;cursor: pointer;">
                        <i class="el-icon-close"></i>
                      </div>
                    </div>
                    <el-upload v-if="files.length < 3" action="" :http-request="uploadOss" :on-success="uploadCover"
                      :on-remove="handleRemove" :show-file-list="false" name="image">
                      <div class="flexCenter uploadBox" style="width: 100px;height: 100px;border:1px dashed #909399;">
                        <i class="el-icon-plus" style="font-size: 26px;"></i>
                      </div>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>



          <div class="shadow flexCloumn mt10" style="height:  calc( 40% - 10px);width: 100%;">
            <div class="titleBar flexStart">
              <div class="div flexStart">流程状态</div>


            </div>
            <processchoose ref="processchoose" v-if="processShow" :dept_id="EditItem.dept_id" :code="process_code"
              v-on:ischooseuser="isChooseuser" v-on:chooseUser="chooseUser" class="flex_1" style="overflow-y: auto;">
            </processchoose>
          </div>
        </div>
        <div class="flexCloumnSingleCenter boxShadow" style="height: 100%;width: calc( 60% - 10px);">
          <el-row class="flexStart w_100">
            <!--            <el-popover placement="top-start" width="900" trigger="click">-->
            <!--              <SXListTable ref="SXListTable"></SXListTable>-->
            <!--              <el-button slot="reference" size="mini" type="primary" style="margin-left: 10px;">选择耗材</el-button>-->
            <!--            </el-popover>-->
            <el-button size="mini" type="primary" @click="dialogVisible_zcList = true">选择耗材
            </el-button>
            <el-button size="mini" type="danger" @click="delSlct" style="margin-left: 10px;">移除耗材</el-button>
          </el-row>
          <el-row class="flex_1 w_100 mt10">
            <el-table ref="mySGtable" :data="arr_json" height="100%" @selection-change="handleSelectionChange" stripe
              borderstyle="width:fit-content;width:100%;">

              <el-table-column type="selection" width="55" align="center" />
              <el-table-column type="index" label="序号" width="60" align="center" />
              <!--              <el-table-column prop="title" label="耗材名称/品牌/型号/分类" width="200" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <div class="flexStart">
                    <div style="color: #409EFF;">{{ scope.row.title }}</div>&nbsp;
                    <div>{{ scope.row.pinpai }}</div>&nbsp;
                    <div>{{ scope.row.xinghao }}</div>&nbsp;

                  </div>
                  <div class="flexStart" style="color: #909399;">分类：{{ scope.row.typename1 }}</div>
                </template>
              </el-table-column>-->
              <el-table-column prop="title" label="耗材名称" show-overflow-tooltip width="200" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">耗材名称</span>
                </template>
              </el-table-column>
              <el-table-column width="100" prop="store_num" label="剩余库存">
                <template slot="header">
                  <span style="color: #F56C6C">剩余库存</span>
                </template>
              </el-table-column>
              <el-table-column prop="jine" width="150" show-overflow-tooltip align="center">
                <template slot="header">
                  <span style="color: #F56C6C">*申领数量</span>
                </template>
                <template slot-scope="scope">
                  <el-input type="number" v-model="scope.row.slNum" @input="e=>handleCGData(e,scope.row,scope.$index)"
                            class="cgInput" :controls="false" size="mini" :min="0" @mousewheel.native.prevent style="max-width: 70px !important;"></el-input>
                  <span style="width: 14px;height: auto;margin-left: 4px;display: inline-block;">{{ scope.row.jldw
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="pinpai" label="品牌" width="100" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">品牌</span>
                </template>
              </el-table-column>
              <el-table-column prop="xinghao" label="型号" width="100" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">型号</span>
                </template>
              </el-table-column>
              <!--              <el-table-column prop="typename1" label="分类"  width="150" align="center"/>-->
              <el-table-column width="100" prop="storename" label="所在仓库">
                <template slot="header">
                  <span style="color: #F56C6C">所在仓库</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="60" fixed="left">
                <template slot-scope="scope">
                  <el-tag size="mini" style="cursor: pointer;" type="danger" @click="removeHC(scope.$index)">移除
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
          </el-row>

        </div>
      </div>

      <div slot="footer">
        <el-button type="primary" size="small" @click="saveForm()">提交</el-button>

        <el-button type="primary" @click="dialogVisible_add = false" plain size="small">取消</el-button>
      </div>

    </el-dialog>
    <!--    添加耗材-->
    <SXhcAdd v-if="dialogVisible_addsxhc" :dialogVisible_addsxhc="dialogVisible_addsxhc"></SXhcAdd>

    <!--添加分类-->
    <el-dialog title="实训耗材分类" :visible.sync="dialogVisible_sxType" width="600px" custom-class="width_800 cus_dialog">

      <sxType name="实训耗材分类" type="sx" parentPage="sxlist"></sxType>

    </el-dialog>

    <!--        选择耗材-->
    <SXListTable v-if="dialogVisible_zcList" :dialogVisible_zcList="dialogVisible_zcList" :isAll="1" dialogTitle="选择耗材"
      ref="SXListTable"></SXListTable>


    <!--查看-->
    <SLDetail v-if="dialogVisible_detail" :dialogVisible_detail="dialogVisible_detail" :dialogTitle="dialogTitle"
      :EditItem1="EditItem"></SLDetail>

    <!--审核-->
    <SLVerify v-if="dialogVisible_verify" :dialogVisible_verify="dialogVisible_verify" :dialogTitle="dialogTitle"
      :EditItem="EditItem"></SLVerify>


    <Deptuser v-if="showUserDialog" @getUser="getUser" @closeUser="closeUser"></Deptuser>

    <biaoqian v-if="showBiaoqian" :zclist="bqZcList"></biaoqian>
    <div style="display: none;">
      <el-upload action="" :multiple="true" :http-request="uploadOss" :on-success="uploadAttch" :show-file-list="false"
        name="image">

        <el-button size="mini" id="addXlsbtn">+</el-button>
      </el-upload>
    </div>


    <el-dialog title="错误日志" :visible.sync="dialogErrorVisible" :close-on-click-modal="false">
      <div style="padding-top: 10px;" v-for="item in ErrLog">{{ item }}</div>
    </el-dialog>

    <!--预览图片-->
    <el-dialog width="600px" :visible.sync="dialogVisibleImageUrl" @close="dialogVisibleImageUrl = false"
      :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

    <!--打印单-->
    <el-dialog :title="dialogTitle" :visible="dialogVisible_print" :close-on-click-modal="false" width="900px"
      custom-class="cus_dialog cus_dialog_print" @close="dialogVisible_print=false">
      <div style="text-align: right; position: absolute; top:2px; right: 50px;">
        <el-button v-print="'#printMe'" icon="el-icon-printer" type="warning">打印</el-button>
      </div>
      <SLPrintForm v-if="dialogVisible_print" :printId="printId"></SLPrintForm>

    </el-dialog>
  </div>
</template>

<script>
import Pagination from '../../com/pagination.vue'
import Deptuser from '../../com/deptuser.vue'
import SXhcAdd from "../components/SXhcAdd";
import biaoqian from "../zc/biaoqian";
import processchoose from "../../com/processchsmini";

export default {
  components: {
    Pagination,
    Deptuser,

    biaoqian,
    processchoose,
    SXShenLTable: () => {
      return import('./com_sxshenling/SpecialSLTable.vue')
    },
    SXListTable: () => {
      return import('../components/SXListTable.vue')
    },
    SLDetail: () => {
      return import('./com_sxshenling/SLDetail.vue')
    },
    SLVerify:()=>{
      return import('./com_sxshenling/SLVerify.vue')
    },
    sxType: () => {
      return import('../components/Type.vue')
    },
    SXhcAdd: () => {
      return import('../components/SXhcAdd')
    },
    SLPrintForm: () => {
      return import('../../../h5/print/sl.vue')
    },
  },
  data () {
    return {
      //--审批流程用----
      processShow:false,
      process_code: "special_sl",
      process_id:0,
      needChoose: false,//是否需要选择下级
      choosedUser: [],//已选择的用户
      //--审批流程用----
      curDate: '',
      userinfo: {},
      searchForm: {},
      dialogTitle: '新增',
      dialogVisible_add: false,
      dialogVisible_sxType: false,
      EditItem: {
        dept_id:"",
        arr_json: ''
      },
      arr_json: [],
      dialogVisibleImageUrl: false,
      dialogImageUrl: '',//预览图片
      dialogVisible_addsxhc: false,
      dialogVisible_detail: false,
      dialogVisible_verify:false,
      dialogVisible_verify_ck:false,
      multipleSelection: [],
      queryForm: {},
      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },

      typeList: [],
      areaList: [],
      files: [],
      logList: [],
      temp_list: [],//todo 未用备用
      showUserDialog: false,
      userDialogType: null,
      drawer: false,//高级搜索面板
      printObj: {
        id: "#printDiv",
        popTitle: "",
        extraCss: "",
        extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>'
      },
      showPrinter: false,
      showBiaoqian: false,
      bqZcList: [],
      dialogErrorVisible: false,
      ErrLog: [],
      page_hc: {
        current_page: 1,
        pre_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      dialogVisible_zcList: false,
      purchaseType: [],
      dialogVisible_print:false,
      printId:'',
      deptTree: [],
      deptCascaderProps: {
        label: "dept_name",
        value: "id",
        children: "children",
        emitPath: false,
        checkStrictly: true,
      },
    }
  },
  mounted () {
    this.getType();
    this.getPurchaseType()
    this.getArea();
    this.getCurDate();
    this.getCreateUser()
    //--审批流程用----
    this.getProcessID()
    this.getOrgList()
  },
  methods: {
    //--审批流程用----
    isChooseuser(e) {//设置是否需要选人
      this.needChoose = e
    },
    chooseUser(e) {//设置选择的人
      this.choosedUser = e
    },
    getProcessID() {
      this.$http.post("/api/form_process", {
        process_code: this.process_code
      }).then(res => {
        this.process_id = res.data.id

      })
    },
    //--审批流程用----

    getCreateUser() {
      this.$http.post("/api/get_teacher_info").then(res => {
        this.userinfo = res.data
      })
    },
    getPurchaseType() {
      this.$http.post("/api/sys_dictionary_list", {key_type: "purchaseType"}).then(res => {
        if (res.data && res.data.length > 0) {
          this.purchaseType = JSON.parse(res.data[0].key_values)
        }
      })
    },
    getOrgList() {
      let _this = this;
      return new Promise((resolve) => {
        this.$http.post("/api/teacher_department_tree").then((res) => {
          if (res.data && res.data.length > 0) {
            _this.deleteEmptyGroup(res.data).then((result) => {
              _this.deptTree = result;
            });
          }
        });
      });
    },
    changeDept(e) {
      let labels = this.$refs.treeCascader.getCheckedNodes()[0].pathLabels
      if (labels && labels.length > 0) {
        this.EditItem.dept_name = labels[labels.length - 1]
        console.log(this.EditItem.dept_name);
      }
    },
    // 新增
    add() {
      let _this = this
      this.dialogTitle = '新增申领单'
      this.dialogVisible_add = true
      this.EditItem = {
        ly_date: _this.curDate,
      }
      if (this.userinfo.dept && this.userinfo.dept.length > 0) {
        this.EditItem.dept_name = this.userinfo.dept[0].dept_name
        this.EditItem.dept_id = this.userinfo.dept[0].id
      } else {
        this.EditItem.dept_name = ''
        this.EditItem.dept_id = ''
      }
      this.arr_json = []
      this.files = []
      this.EditItem = { ..._this.EditItem }
      this.files = [..._this.files]
      _this.$nextTick(() => {
        console.log(_this.arr_json)
        _this.$forceUpdate()
        setTimeout(() => {
          this.processShow = true
        },500);
      })
    },

    uploadCover(e) {
      this.files.push({ fileName: e.fileName, src: e.src, name: e.fileName, url: e.src })
    },
    delImage(idx) {
      this.files.splice(idx, 1)
    },
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url;
      this.dialogVisibleImageUrl = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.files = fileList
    },
    uploadPreview(file) {
      // console.log(879,file)
      window.open(file.src || file.url)
    },
    refreshType() {
      console.log(463, 'refreshType')
      this.getType()
      this.dialogVisible_sxType = false
    },
    chooseHC(row) {
      let _this = this
      let obj = {}, arr = []
      obj = JSON.parse(JSON.stringify(row))
      arr = JSON.parse(JSON.stringify(_this.arr_json))
      obj.img = ''
      obj.slNum = obj.slNum ? obj.slNum : obj.store_num
      if (arr && arr.length > 0) {
        if (_this.dep_Arr1(arr, obj.id)) {//dep_Arr1 id存在为false 不存在返回true
          arr.push(obj)
          _this.arr_json = arr
        } else {
          _this.$message.warning(`${obj.title}已存在,不要重复选择哦~`)
        }
      } else {
        arr.push(obj)
        _this.arr_json = arr
      }
      _this.$nextTick(() => {
        console.log(_this.arr_json)
        _this.$forceUpdate()
      })

    },
    dep_Arr1(arr, id) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.id == id) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },
    removeHC(idx) {
      let _this = this
      _this.arr_json.splice(idx, 1)
    },
    handleCGData(e,row,idx) {
      console.log('handleCGData', e)
      if (e > row.store_num) {
        this.$message.warning('申领数量不能超过剩余库存量~')
        this.arr_json[idx].slNum = row.store_num
        return
      }
    },
    handleRemark() {
      console.log(this.arr_json)
    },
    handleExternalLinks() {
      console.log(this.arr_json)
    },
    uploadCover1(e, b, c, index) {
      // console.log(1082,e,b,c,index)
      let _this = this
      this.arr_json[index].img = e.src
      this.arr_json = [..._this.arr_json]
      _this.$nextTick(() => {
        _this.$forceUpdate()
      })
      // this.files.push({fileName: e.fileName, src: e.src, name: e.fileName, url: e.src})
    },
    delImage1(index) {
      let _this = this
      this.arr_json[index].img = ''
      this.arr_json = [..._this.arr_json]
      _this.$nextTick(() => {
        _this.$forceUpdate()
      })
    },

    editItem(row, flag) {
      let _this = this
      this.dialogTitle = '编辑'
      this.dialogVisible_add = true
      let obj = JSON.parse(JSON.stringify(row))


      this.EditItem = { ..._this.EditItem }


      obj.arr_json = obj.arr_json ? JSON.parse(obj.arr_json) : []
      obj.process_json = obj.process_json ? JSON.parse(obj.process_json) : []
      obj.files = obj.files ? JSON.parse(obj.files) : []
      obj.hc_list = obj.hc_list ? JSON.parse(obj.hc_list) : []

      this.EditItem = obj
      this.arr_json = obj.hc_list
      this.files = obj.files
      _this.$nextTick(() => {
        console.log(_this.arr_json)
        _this.$forceUpdate()
      })
    },
    view(row,type) {
      console.log(row,type)
      let obj = JSON.parse(JSON.stringify(row))
      obj.arr_json = obj.arr_json ? JSON.parse(obj.arr_json) : []
      obj.process_json = obj.process_json ? JSON.parse(obj.process_json) : []
      obj.files = obj.files ? JSON.parse(obj.files) : []
      obj.files_ck = []
      // obj.hc_list = obj.hc_list ? JSON.parse(obj.hc_list) : []
      if(obj.hc_list){
        let arr = JSON.parse(obj.hc_list)
        arr.map(a=>{
          let links = []
          a.links = []
          if (a.externalLinks) {
            if (a.externalLinks.indexOf(';') > 0) {
              links = a.externalLinks.split(';')
              a.links = links
            }else if (a.externalLinks.indexOf('；') > 0) {
              links = a.externalLinks.split('；')
              a.links = links
            }else {
              a.links = [a.externalLinks]
            }
          }

        })
        obj.hc_list = arr
      }
      this.EditItem = obj
      if(type == 'verify'){
        this.dialogTitle = '审核'
        this.dialogVisible_verify = true
      }else {
        this.dialogTitle = '申领单详情'
        this.dialogVisible_detail = true
      }

      console.log(this.EditItem.process_json)

    },
    qianshou(row) {
      console.log(row)

      this.$http.post("/api/zc_hc_sl_form", {
        id: row.id
      }).then(res => {
        console.log(88888,res.data)
        let obj = res.data
        obj.arr_json = obj.arr_json ? JSON.parse(obj.arr_json) : []
        obj.process_json = obj.process_json ? JSON.parse(obj.process_json) : []
        obj.files = obj.files ? JSON.parse(obj.files) : []
        obj.files_ck = obj.files_ck ? JSON.parse(obj.files_ck) : []
        if(obj.hc_list){
          obj.hc_list&&obj.hc_list.map(h=>{
            h.ckNum = h.slNum
            h.img = h.ck_status==1?h.img:''
            h.signUrl = h.ck_status==1?h.signUrl:''
            h.ck_status = h.ck_status==1?h.ck_status:0
          })
        }
        this.EditItem = obj
        this.dialogTitle = '签收单'
        this.dialogVisible_verify_ck = true
      })
    },
    preCheckData(e) {

      let DataInfo = this.EditItem;


      if (this.needChoose && this.choosedUser.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择审批人'
        });
        this.$refs['processchoose'].choseSpUser({},0)
        return false;
      }
      return true

    },

    onSearch() {
      this.page.current_page = 1
      this.$refs.SXShenLTable.getList()

    },
    onReset() {
      this.queryForm = {}
      this.page.current_page = 1
      this.$refs.SXShenLTable.getList()

    },
    daochu(command) {
      return
      document.getElementById("addXlsbtn").click()

    },
    dep_Arr(arr) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.store_num <= 0) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },

    getType() {
      let _this = this
      this.$http.post("/api/zc_type", { type: 'hc' }).then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.typeList = result
        })
      })
    },
    getArea() {
      let _this = this
      this.$http.post("/api/zc_store").then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.areaList = result
        })
      })
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }

        traversal(treeData)
        resolve(treeData)
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },

    saveForm() {
      let flag = 1
      //--审批流程用----
      if (!this.preCheckData()) {
        return
      }
      //--审批流程用----
      let _this = this
      let params = {}
    
      params = { ..._this.EditItem }
      if (!params.sl_remark) {
        _this.$message.warning('请填写申领原因~')
        return
      }
      if (!_this.arr_json||_this.arr_json==0) {
        _this.$message.warning('请至少添加一条耗材~')
        return
      }else{
        _this.arr_json.map(a=>{
          console.log(755755,a.slNum)
          if(!a.slNum||a.slNum==0){
            flag = 0
            setTimeout(function (){
              _this.$message.warning(`请填写${a.title}的申领数量且不能为0~`)
            },50)
            return
          }else if (a.slNum > a.store_num) {
            flag = 0
            this.$message.warning(`${a.title}的申领数量不能超过剩余库存量~`)
            return;
          }
        })
        params.hc_list = _this.arr_json
        params.title = `${_this.userinfo.username}发起了${_this.arr_json[0].title}${_this.arr_json.length>1?('等'+_this.arr_json.length+'件物品'):''}的申领单`
      }
      params.files = JSON.stringify(_this.files)

      //--审批流程用----
      params.sp_status = 0;
      params.process_id = this.process_id
      params.checkedUsers = this.choosedUser
      params.zc_type='sx'
      params.sl_type = 2
      //--审批流程用----
      if(flag){
        this.$http.post('/api/zc_hc_sl_edit', params).then(res => {
          _this.dialogVisible_add = false;
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          _this.$refs.SXShenLTable.getList()
        })
      }

    },
    removeItem(item) {
      let _this = this
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/zc_hc_sl_delete", {
          id: item.id,
          zc_type:'sx',
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功'
          });

          _this.$refs.SXShenLTable.getList()

        })

      }).catch(() => {

      });
    },
    delSlct() {
      let _this = this
      if (_this.multipleSelection.length == 0) {
        _this.$message.warning('请选择至少一条数据')
      } else {
        _this.$confirm('此操作将移除所选数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(_this.multipleSelection, _this.arr_json)

          _this.multipleSelection.map(a => {
            let len = _this.arr_json.length
            for (let i = len - 1; i >= 0; i--) {
              if (a.id == _this.arr_json[i].id) {
                _this.arr_json.splice(i, 1)
                console.log(a.id, _this.arr_json[i].id, _this.arr_json)

              }
            }

          })
        }).catch(() => {

        });
      }
    },





    showUser(type) {
      if (type) {
        this.userDialogType = type
      }
      this.showUserDialog = true
    },
    del_target_users(index) {
      let _this = this
      this.EditItem.target_users.splice(index, 1)
      this.EditItem = { ..._this.EditItem }
    },
    getUser(e) {
      console.log(e)
      let _this = this
      let arr = []
      if (e && e.length > 0) {
        if (this.userDialogType == 1) {
          e.map((item, idx) => {
            arr.push({
              union_id: item.union_id,
              name: item.name,
            })
            if (_this.EditItem.target_users && _this.EditItem.target_users.length > 0) {
              _this.EditItem.target_users.map(orgnl => {
                if (orgnl.union_id == item.union_id) {
                  arr.splice(idx, 1)
                }
              })
            }
          })
          _this.EditItem.target_users = _this.EditItem.target_users.concat(arr)
          _this.EditItem = { ..._this.EditItem }
        } else if (this.userDialogType == 2) {
          _this.EditItem.target_uid = e[0].union_id
          _this.EditItem.name = e[0].name
          _this.EditItem = { ..._this.EditItem }
        }

      }
      _this.showUserDialog = false
    },
    closeUser() {
      this.showUserDialog = false
    },

    print_ruku() {
      this.$alert("敬请期待")
      return;
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请选择一条数据');
        return
      } else {
        this.showPrinter = true
      }
    },
    getCurDate() {
      let d = new Date();
      let year = d.getFullYear(); //获取完整的年份(4位)
      let month = (d.getMonth() + 1).toString().padStart(2, '0'); //获取当前月份(0-11,0代表1月)
      let date = (d.getDate()).toString().padStart(2, '0'); //获取当前日(1-31)
      d.getDay(); //获取当前星期X(0-6,0代表星期天)
      d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
      d.getHours(); //获取当前小时数(0-23)
      d.getMinutes(); //获取当前分钟数(0-59)
      d.getSeconds(); //获取当前秒数(0-59)
      d.getMilliseconds(); //获取当前毫秒数(0-999)
      d.toLocaleDateString(); //获取当前日期
      let mytime = d.toLocaleTimeString(); //获取当前时间
      d.toLocaleString(); //获取日期与时间
      this.curDate = `${year}-${month}-${date}`
    },
    uploadAttch(e) {
      console.log(e)
      this.$http.post("/api/import_zc_xls", { url: e.src }).then(res => {
        if (res.data.errordata.length > 0) {
          this.ErrLog = res.data.errordata
          this.dialogErrorVisible = true;

        }
        this.$refs.SXShenLTable.getList()

      })
    },
    print(row){
      this.printId = row.id
      this.dialogVisible_print = true
      this.dialogTitle = '打印申领单'
    },
  }
}
</script>
<style lang="less" type="text/less">
.pagesxshenling {
  box-shadow: 0 0 5px #409eff81;
  padding-left: 10px;
  padding-bottom: 10px;
  div {box-sizing: border-box!important;}

  position: relative;

  .addDialog {
    background-color: #fff;
    //border: 1px solid #409eff;
    box-shadow: 0 0 5px #409eff81;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: calc( 100% );
    height: calc( 100% );
    .el-dialog__wrapper {
      position: absolute !important;
    }

    .v-modal {
      position: absolute !important;
    }
    }
    .myDialogBox {
      width: 100%;
      height: 100%;
      .shadow {
        box-shadow: 0 0 5px #409eff81;
      }

      .titleBar {
        width: 100%;
        height: 30px;
        background-color: #2469f351;
        font-size: 14px;
        flex-wrap: wrap;
      }

      .titleBar .div {
        width: 100%;
        height: calc(100% - 2px);
        padding-left: 22px;
        border-left: none;
        color: #303133;
        font-weight: 700
      }

      .el-form-item {
        margin-bottom: 0;

        .el-textarea__inner {
          margin: 10px 0;
        }
      }

      .cgInput {
        max-width: 120px;

        .el-input__inner {
          color: #F56C6C;
        }
      }

      .cgInput input::-webkit-outer-spin-button,
      .cgInput input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      .cgInput input[type="number"] {
        -moz-appearance: textfield;
      }


    }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .labelCss {
    .el-form-item__label {
      color: #909399 !important;
      font-size: 12px;
    }
  }

  .el-input,
  .el-select,
  .el-cascader {
    width: 100% !important;
  }

  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown+.el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .cus_dropdown {
    .el-button--primary {
      color: #409EFF !important;
      background-color: #Ecf5ff !important;
      border-color: #b3d8ff !important;
    }
  }

  .el-divider {
    margin-top: 0;
  }

  .el-drawer__wrapper {
    top: 70px;
  }

  .drawer_box,
  .targetUsers {
    padding: 10px;

    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .updimage {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top;
    margin: 0 20px 0 0;
    border: 1px dotted #ccc;
    background-size: 100% auto;
    background-position: center;
    //overflow: hidden;
    background-repeat: no-repeat;
    //cursor: pointer;
  }

  .uploadBox:hover {
    border-color: #409eff !important;
    color: #409eff;
  }

  .cus_page {
    margin-top: 10px;

    .el-pagination__sizes {
      width: 130px;
    }
  }
}
</style>
